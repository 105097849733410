import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Table, Toastr } from "@bigbinary/neetoui";
import Container from "@bigbinary/neeto-molecules/Container";
import MenuBar from "@bigbinary/neeto-molecules/MenuBar";

import activeAppsApi from "src/apis/active_apps";
import { setToLocalStorage } from "../helpers/storage";
import { resetAuthTokens } from "../apis/axios";

const Addon = () => {
  const [activeApps, setActiveApps] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const columnData = [
    {
      title: "Name",
      dataIndex: "app_name",
      key: "app_name",
    },
    {
      title: "Age",
      dataIndex: "timestamp",
      key: "timestamp",
    }
  ];

  const menuBarItems = {
    general: [
      {
        label: "All pods",
      }
    ]
  };

  const fetchApps = async () => {
    try {
      setIsLoading(true);
     const { data: { activeApps } } = await activeAppsApi.fetch();
      setActiveApps(JSON.parse(activeApps));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, []);

  const handleLogout = async () => {
    try {
      setToLocalStorage({
        authToken: null,
        email: null,
      });
      resetAuthTokens();
      Toastr.success("You have successfully logged out")
      history.push("/pod-idling/admin/login")
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Container >
      <div class="flex w-full flex-row-reverse">
        <Button 
          label="Logout" 
          onClick={handleLogout}
          className="m-2"
          style="secondary"
        />
      </div>
     
      <div className="flex">
        <MenuBar showMenu={true} title={"Dashboard"}>
          {menuBarItems.general.map(({ key, label, count }) => (
            <Link
              key={key}
              to="/pod-idling/admin"
            >
              <MenuBar.Block
                count={count}
                label={label}
              />
            </Link>
          ))}
        </MenuBar>

        <div className="h-screen">
          <Table
            fixedHeight
            columnData={columnData}
            defaultPageSize={2000}
            loading={isLoading}
            rowData={activeApps}
            loading={isLoading}
            scroll={{ x: "100%" }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Addon;
