import CoffeeEnergy from "src/assets/images/energy-energized.gif";
import RechargeWithCoffee from "src/assets/images/recharge-with-coffee.gif";
import Shock from "src/assets/images/shock.gif";
import Motherboard from "src/assets/images/motherboard.gif";
import Horn from "src/assets/images/horn.gif";
import InternalServerError from "src/assets/images/internal-server-error.gif";

export const linesWithGif = [
  {
    status: "initiated",
    line: "We are giving it a wake-up jolt.",
    gif: CoffeeEnergy,
    className: "w-40 h-40 md:w-80 md:h-80",
  },
  {
    status: "initiated",
    line: "We are giving it a wake-up jolt of virtual expresso.",
    gif: CoffeeEnergy,
    className: "w-40 h-40 md:w-80 md:h-80",
  },
  {
    status: "initiated",
    line: "We are currently filling our bucket with cold water to give it a wake-up jolt.",
    gif: Shock,
    className: "w-40 h-40 md:w-80 md:h-80",
  },
  {
    status: "initiated",
    line: "We are giving it a tickle from the motherboard.",
    gif: Motherboard,
    className: "w-auto h-40 md:w-auto md:h-96",
  },
  {
    status: "initiated",
    line: "We are currently brewing coffee for it. You know some folks can't function without coffee.",
    gif: RechargeWithCoffee,
  },
  {
    status: "initiated",
    line: "We are looking for our army grade bugle horn to wake up this sleepy giant.",
    gif: Horn,
    className: "w-auto h-40 md:w-auto md:h-96",
  },
  {
    status: "boot_up_error",
    line: "Your app has taken too long to wake up. This is not expected. Our team has been notified of this and is looking into it. Please retry after some time.",
    gif: InternalServerError,
    className: "h-auto w-80 md:h-80 md:w-auto",
  },
  {
    status: "image_pull_error",
    line: "Sorry, we are unable to boot up your app at this moment. Our team has been notified and is looking into it.",
    gif: InternalServerError,
  },
];

export const getAppInactivityMessage = (status, availableTime) => {
  switch (status) {
    case "scaled_up":
      return "This app is scaled up. You can refresh the page now.";
    case "initiated":
      return `This app has gone to sleep due to inactivity. Your app will be available in approximately ${availableTime} seconds.`;
    case "boot_up_error":
      return "Your app has taken too long to wake up. This is not expected. Our team has been notified of this and is looking into it. Please retry after some time.";
    case "image_pull_error":
      return "Sorry, we are unable to boot up your app at this moment. Our team has been notified and is looking into it.";
    default:
      return `This app has gone to sleep due to inactivity. Your app will be available in approximately ${availableTime} seconds.`;
  }
};

export const getAppInactivityEmoji = (status) => {
  switch (status) {
    case "scaled_up":
      return "😊";
    case "initiated":
      return "😴";
    case "image_pull_error":
      return "Error Code: 503";
    case "boot_up_error":
      return "Error Code: 504";
    default:
      return "😴";
  }
};
