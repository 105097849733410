import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { either, isEmpty, isNil } from "ramda";
import PageLoader from "@bigbinary/neeto-molecules/PageLoader";
import { getFromLocalStorage } from "src/helpers/storage";
import Admin from "./Admin";
import SignIn from "./LoginPage";
import PrivateRoute from "./Common/PrivateRoute";
import { setAuthHeaders, registerIntercepts } from "../apis/axios";
import Sleep from "./Sleep";
import { createSubscription } from "src/channels/subscription";
import { getAppInactivityEmoji, getAppInactivityMessage } from "./utils";
import { globalProps } from "@bigbinary/neeto-commons-frontend/initializers";

const Main = () => {
  const [sleepEmoji, setSleepEmoji] = useState("");
  const [sleepMessage, setSleepMessage] = useState("");
  const [appStatus, setAppStatus] = useState(globalProps.status);
  const [showTimer, setShowTimer] = useState(true);
  const [loading, setLoading] = useState(true);
  const authToken = getFromLocalStorage("authToken");
  const isLoggedIn = !either(isNil, isEmpty)(authToken);
  const sleepTime = globalProps.sleepTime;

  useEffect(() => {
    registerIntercepts();
    setAuthHeaders(setLoading);
  }, []);

  const getSubdomain = () => {
    const hostname = window.location.hostname;
    return hostname.split(".")[0];
  };

  const handleChannelResponse = ({ status }) => {
    if (status === "scaled_up") {
      window.location.reload();
    } else {
      setAppStatus(status);
    }
  };

  useEffect(() => {
    if (appStatus === "scaled_up") {
      return;
    }

    const message = getAppInactivityMessage(appStatus, sleepTime);
    const emoji = getAppInactivityEmoji(appStatus);

    setSleepMessage(message);
    setSleepEmoji(emoji);
  }, [appStatus]);

  useEffect(() => {
    if (
      !globalProps?.isV2DowntimeServiceEnabled ||
      appStatus !== "initiated" ||
      window.location.hostname ===
        "neeto-deploy-downtime-service.neetodeployapp.com" ||
      window.location.hostname === "neeto-deploy-downtime-service.lvh.me" ||
      window.location.pathname === "/pod-idling/admin/login" ||
      window.location.pathname === "/pod-idling/admin"
    ) {
      return;
    }

    const statusSubscription = createSubscription(
      {
        channel: "SiteRequestChannel",
        app_name: getSubdomain(),
      },
      handleChannelResponse
    );

    return () => {
      statusSubscription?.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="h-screen">
        <PageLoader />
      </div>
    );
  }

  if (
    globalProps?.isV2DowntimeServiceEnabled &&
    window.location.hostname !=
      "neeto-deploy-downtime-service.neetodeployapp.com" &&
    window.location.hostname != "neeto-deploy-downtime-service.lvh.me" &&
    window.location.pathname != "/pod-idling/admin/login" &&
    window.location.pathname != "/pod-idling/admin"
  ) {
    return (
      <Sleep
        status={appStatus}
        sleepMessage={sleepMessage}
        sleepTime={sleepTime}
        setAppStatus={setAppStatus}
        showTimer={showTimer}
        setShowTimer={setShowTimer}
      />
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/pod-idling/admin/login" component={SignIn} />
        <PrivateRoute
          path="/pod-idling/admin"
          redirectRoute="/pod-idling/admin/login"
          condition={isLoggedIn}
          component={Admin}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Main;
