// eslint-disable-next-line @bigbinary/neeto/use-create-subscription-util-function
import { createConsumer } from "@rails/actioncable";

const getWebsocketURL = () => {
  const websocketMetaTag = document.querySelector(
    'meta[name="action-cable-url"]'
  );
  if (!websocketMetaTag) {
    console.error("Meta tag for WebSocket URL not found");
    return null;
  }

  const websocketURL = websocketMetaTag.content;
  if (!websocketURL) {
    console.error("WebSocket URL is empty");
    return null;
  }

  return websocketURL;
};

const websocketURL = getWebsocketURL();
if (!websocketURL) {
  throw new Error("WebSocket URL is required for creating the consumer");
}

const consumer = createConsumer(websocketURL);

export default consumer;
