import React, { useEffect, useMemo, useState } from "react";
import { Container } from "neetoui/layouts";
import { Typography } from "neetoui";
import { linesWithGif } from "./utils";

const Sleep = ({
  status,
  sleepMessage,
  sleepTime,
  setAppStatus,
  showTimer,
  setShowTimer,
}) => {
  const [counter, setCounter] = useState(sleepTime);

  // Use useMemo to ensure the random GIF is chosen only on re-render, not every render
  const randomLineWithGif = useMemo(() => {
    console.log(status);
    if (status === "initiated") {
      const randomIndex = Math.floor(
        Math.random() *
          linesWithGif.filter((line) => line.status === "initiated").length
      );
      return linesWithGif[randomIndex];
    } else {
      return linesWithGif.find((line) => line.status === status);
    }
  }, [status]);

  useEffect(() => {
    if (counter > 0) {
      const timerId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setAppStatus("boot_up_error");
      setShowTimer(false);
    }
  }, [counter, setAppStatus]);

  return (
    <Container>
      <div className="flex flex-col justify-center items-center w-full h-full space-y-5 p-4">
        <img
          src={randomLineWithGif.gif}
          alt="Random Sleep GIF"
          className={randomLineWithGif.className}
        />
        <div className="text-center space-y-2">
          {status === "initiated" && (
            <Typography style="body2">
              {
                "This app has gone to sleep due to inactivity to save you money."
              }
            </Typography>
          )}
          <Typography style="body2">{randomLineWithGif.line}</Typography>
          {status === "initiated" && (
            <Typography style="body2">{`It would be up and running in approximately ${counter} seconds.`}</Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Sleep;
