const setToLocalStorage = ({ authToken, email }) => {
  localStorage.setItem("authToken", JSON.stringify(authToken));
  localStorage.setItem("authEmail", JSON.stringify(email));
};

const getFromLocalStorage = key => {
  const value = localStorage.getItem(key);
  const response = value ? JSON.parse(value) : "";

  return response;
};

const clearLocalStorageCredentials = () => {
  setToLocalStorage("authEmail", null);
  setToLocalStorage("authToken", null);
};

export { setToLocalStorage, getFromLocalStorage, clearLocalStorageCredentials };
