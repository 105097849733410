import * as Yup from "yup";

export const INITIAL_FORM_VALUES = { email: "", password: "" };

export const LOGIN_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email required"),
  password: Yup.string().required("Password required"),
});
