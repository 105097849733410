import React,{useState} from "react";

import { Form, Formik } from "formik";
import { Button, Input } from "@bigbinary/neetoui/formik";
import { assoc } from "ramda";
import authApi from "../../apis/auth";
import { INITIAL_FORM_VALUES, LOGIN_FORM_VALIDATION_SCHEMA } from "./constants";
import { setToLocalStorage } from "../../helpers/storage";
import { setAuthHeaders } from "../../apis/axios";

const SignInForm = () => {
  const [loading, setLoading] = useState(false);

  const emailFromSearchParams = () =>
    new URLSearchParams(window.location.search).get("email") || "";

  const emailPrefilledInitialValues = () =>
    assoc("email", emailFromSearchParams(), INITIAL_FORM_VALUES);


  const handleSubmit = async ({email,password}) => {
    setLoading(true);
      try {
        const response = await authApi.login({ login: { email, password } });

        setToLocalStorage({
          authToken: response.data.authentication_token,
          email: email.toLowerCase(),
        });
        setAuthHeaders();
        setLoading(false);
        window.location.href = "/pod-idling/admin"

      } catch (error) {
        logger.error(error);
        setLoading(false);
      }
    };

  return (
    <Formik
      initialValues={emailPrefilledInitialValues()}
      validationSchema={LOGIN_FORM_VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      <Form className="w-full">
        <div className="mb-6">
          <Input
            required
            label="Email"
            name="email"
          />
        </div>
        <div className="mb-6">
          <Input
            required
            label="Password"
            name="password"
            type="password"
          />
        </div>
        <Button
          fullWidth
          label="Login"
          style="primary"
          type="submit"
          loading={loading}
        />
      </Form>
    </Formik>
  );
};

export default SignInForm;
