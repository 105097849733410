import React from "react";
import SignInForm from "./Form";

const SignIn = () => {
  return (
    <div className="flex h-screen w-full justify-center">
      <div className="neeto-ui-bg-white neeto-ui-rounded-lg neeto-ui-shadow-sm w-2/3 min-w-max max-w-lg self-center p-10">
        <h1 className="mb-6 text-xl font-medium">
          Login
        </h1>
        <SignInForm />
      </div>
    </div>
  );
};

export default SignIn;
